import { createContext } from "react";

export let langState = {
    vn: {
      home: "Trang chủ",
      book: "Đặt vé",
      search: "Tìm kiếm vé đã mua",
      language: "Ngôn ngữ",
      flag: "vn",
      pick_finish: "Hoàn thành",
      pick_back: "Quay lại",
      pick_pay: "Gửi đăng ký",
      pick_backhome: "Trở về trang chủ",
      pick_success: "Thành công!",
      pick_fault: "Có lỗi xảy ra",
      pick_bill: "Hóa đơn thanh toán của bạn",
      pick_lackname: "Chưa nhập tên",
      pick_lackphone: "Chưa nhập số điện thoại",
      pick_lackid: "Chưa nhập số CMND/Hộ chiếu",
      pick_lackdate: "Ngày không hợp lệ",
      pick_wrongdate: "Bạn phải chọn ngày sau ngày hôm qua",
      pick_request: "Điền đầy đủ thông tin cá nhân. Hãy kiểm tra thông tin thật chính xác: ",
      ticket_download: "Tải xuống",


      ticket_code: "Mã booking",
      ticket_date_on_ticket: "Ngày sử dụng vé",
      ticket_full_name: "Họ và tên",
      ticket_phone: "Số điện thoại",
      ticket_idcard: "Giấy tờ tùy thân",
      ticket: "Vé",
      ticket_quantity: "Số lượng",
      ticket_adult: "Người lớn/Trẻ em trên 1m4",
      ticket_children: "Trẻ em từ 1m đến 1m4",
      ticket_baby: "Trẻ em dưới 1m",
      ticket_total: "Tổng tiền",
      ticket_note: "Lưu ý",
      ticket_note1: "*Vé trẻ em dành cho khách có chiều cao từ 1m đến 1m4.",
      ticket_note2: "*Vé người lớn dành cho khách có chiều cao trên 1m4.",
      ticket_note3: "*Cung cấp thông tin trên cho quầy lễ tân của khu du lịch và nhận vé của bạn.",
      ticket_note4: "*Để giữ gìn vệ sinh chung, vui lòng không mang đồ tươi sông, đồ nấu nướng vào khu du lịch.",
      ticket_note5: "*Không được mang thú cưng, loa kéo các loại, ..., loa có công suất lớn vào khu du lịch.",
      ticket_note6: "*Quý khách vui lòng tuân thủ các quy định của khu du lịch.",
      ticket_note7: "*Quý khách tự bảo quản mã booking/code vé sau khi xác nhận.",
      ticket_note8: "*Vé chỉ có giá trị sử dụng trong ngày cho một lần vào cổng.",
      ticket_note9: "*Giữ vé của bạn trong suốt quá trình sử dụng dịch vụ.",
      searchpage_label: "Nhập thông tin để tìm kiếm lịch sử đặt vé của bạn",
      searchpage_placeholder: "Nhập mã đặt vé hoặc số điện thoại, số chứng minh nhân dân mà bạn đã dùng để đặt vé",
      searchpage_send: "Gửi thông tin",
      searchpage_title: "Lịch sử đặt vé",
      searchpage_status: "Không tìm thấy kết quả"
    },
    eng: {
      home: "Home",
      book: "Book",
      search: "Search",
      language: "Language",
      flag: "gb",
      pick_finish: "Finish",
      pick_back: "Go back",
      pick_pay: "Send request",
      pick_backhome: "Back to home",
      pick_success: "Success!",
      pick_fault: "Something wrong happens",
      pick_bill: "Your bill",
      pick_lackname: "Lack of name",
      pick_lackphone: "Lack of phone number",
      pick_lackid: "Lack of passport/ID card",
      pick_lackdate: "Invalid date",
      pick_wrongdate: "You must choose date past yesterday",
      pick_request: "Please fill in your personal information correctly: ",
      ticket_download: "Download",

      ticket_code: "Code of booking",
      ticket_date_on_ticket: "Service using date",
      ticket_full_name: "Full name",
      ticket_phone: "Phone number",
      ticket_idcard: "Passport/ID card",
      ticket: "Ticket",
      ticket_quantity: "Quantity",
      ticket_adult: "Adult/Children over 1m4",
      ticket_children: "Children from 1m to 1m4",
      ticket_baby: "Children under 1m",
      ticket_total: "Total",
      ticket_note: "Note",
      ticket_note1: "*Children tickets are for guests from 1m to 1m4.",
      ticket_note2: "*Adult tickets are for guests over 1m4.",
      ticket_note3: "*Provide the above confirmation at the cashier counter of Hot Springs Park and receive your tickets.",
      ticket_note4: "*To maintain general hygiene, please do not bring fresh food, cooking utensils.",
      ticket_note5: "*Do not bring pets, portable speakers of all kinds, ..., speakers with high capacity.",
      ticket_note6: "*Please comply with the regulations of Hot Springs Park.",
      ticket_note7: "*Please keep your booking code after confirmation.",
      ticket_note8: "*Tickets are only valid within day, for one entry.",
      ticket_note9: "*Keep your tickets during service use.",
      searchpage_label: "Type your information to find your history booking",
      searchpage_placeholder: "Type in your booking code, your phone number or your identification number that you used for booking",
      searchpage_send: "Send information",
      searchpage_title: "Booking history",
      searchpage_status: "Cannot find any results"
    }
  };
export const LangContext = createContext(null);
export const LangDispatchContext = createContext(null);