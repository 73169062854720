//import logo from './logo.svg';
import { useReducer } from 'react';
import './App.css';
import { RootBar } from './pages/Root';
import { langState, LangContext, LangDispatchContext } from './components/Language';



function App() {
  const [lang, dispatchLang] = useReducer(langReducer, langState.vn);
  return (
    <LangContext.Provider value={lang}>
      <LangDispatchContext.Provider value={dispatchLang}>
        <RootBar />
      </LangDispatchContext.Provider>
    </LangContext.Provider>
  );
}



function langReducer(lang, action){
  switch(action.type){
    case 'eng': {
      return langState.eng;
    }
    case 'vn': {
      return langState.vn;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export default App;
