import { useParams } from "react-router-dom";
import { BasketCard, EvolvedSearchCard } from "../components/Cards"
import './style.css'
import { useContext, useEffect } from "react";
import jsPDF from "jspdf";
import autoTable, { Table } from "jspdf-autotable";
import { LangContext } from "../components/Language";
import html2pdf from "html2pdf.js";
import logo from '../components/logo.jpg'
// import DataTable from "datatables.net-dt";
// import $ from "jquery";

export function Test(){
    const formatter = new Intl.NumberFormat('vi-VI', {
        style: 'currency',
        currency: 'VND',
    });
    const lang = useContext(LangContext);
    let id = 'bill'
    let value = {
        "paid": false,
        "id": "B20241003022007_405",
        "date_book": "3-9-2024 14:20:7",
        "phone": "0338289786",
        "uid": "030203002297",
        "booking_code": "3-9-2024_383214207",
        "name": "Nguyen Huy Tung",
        "ticket": [
            {
                "name": "GÓI PHỔ THÔNG ( Vé vui chơi từ 8:30 đến 17:30)",
                "num": [
                    0,
                    0
                ],
                "ticket_id": "T2024827154343511"
            },
            {
                "num": [
                    0,
                    1
                ],
                "ticket_id": "T2024827161122242",
                "name": "GÓI NẠP KHÍ ( Vé phổ thông + Ăn trưa )"
            },
            {
                "name": "GÓI TÍCH LỘC ( Vé phổ thông + ăn trưa nhà hàng rồng đỏ )",
                "num": [
                    0,
                    0
                ],
                "ticket_id": "T202482716720739"
            }
        ],
        "date_on_ticket": "10-9-2024",
        "money": 315000
    }
    // useEffect(() => {
    //     return () => new DataTable('#bill', {
    //         layout: {
    //             topStart: {
    //                 buttons: ['pdf']
    //             }
    //         }
    //     });
    // })
    
    const handle = () => {
        // const doc = new jsPDF();
        // autoTable(doc, {html : '#bill1', theme: 'plain', styles: {lineWidth: 1}, horizontalPageBreak: true});
        // doc.save('test.pdf');
        var element = document.getElementById('bill1');
        var worker = html2pdf().from(element).save()
    }
    // return(<>
    //     <button onClick={handle}>Download</button>
    //     <table id="bill1">
    //         <tr>
    //             <th>id</th>
    //         </tr>
    //         <tr>
    //             <td>01</td>
    //         </tr>
    //     </table>
    // </>)
    return(
        <>
        <button onClick={handle}>Download</button>
        <div className='card card-body' id='bill1'>
            <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                <div>
                                    <img style={{marginLeft: '40%'}} src={logo} alt='Not found' width={100}/>
                                </div>
                            </th>
                            <th><div style={{paddingLeft: '40%'}}>Nam Đà Thành</div></th>
                        </tr>
                        <tr>
                            <div style={{paddingLeft: '38%'}}>Hot Spring Park Confirmation</div>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{lang.ticket_code}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_date_on_ticket}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_full_name}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_phone}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_idcard}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table className='table table-striped-columns'>
                <thead>
                    <tr>
                        <th>{lang.ticket}</th>
                        <th></th>
                        <th>{lang.ticket_quantity}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        value.ticket.map(element => {
                            if(element.num[0] != 0 || element.num[1] != 0) return(
                                <>
                                <tr>
                                    <td rowSpan={2}>
                                        <h6>{element.name[`${lang.flag}`]}</h6>
                                    </td>
                                    <td>{lang.ticket_adult}</td>
                                    <td>{element.num[0]}</td>
                                    
                                </tr>
                                <tr>
                                        <td>{lang.ticket_children}</td>
                                        <td>{element.num[1]}</td>
                                        </tr>
                                </>
                            )
                        })
                    }
                    <tr>
                        <td>{lang.ticket_total}</td>
                        <td colSpan={2}>{formatter.format(value.money)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table'>
                <thead>
                    <tr>
                        <th>{lang.ticket_note}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                    <tr>
                        <td>*</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )

}

export function Basket(){
    const {id} = useParams();
    let sum = 0;
    const ticket = JSON.parse(sessionStorage.getItem("ticket"));
    const basket = JSON.parse(sessionStorage.getItem("basket"));
    return (
        <>
        <div className="Basket">
            {
                basket ? basket.map((item, index) => {
                    sum += ticket[item.id]["price"] * item.num;
                    return (
                        <BasketCard key={index} name={ticket[item.id]["name"]} price={ticket[item.id]["price"]} num={item.num} date={item.date} onExcludeClick={() => {
                            let content;
                            if(item.num > 0){
                                content = "Success!";
                                item.num--;
                            }
                            else{
                                content = "Bạn đã bỏ hêt vé này ra khỏi giỏ";
                            }
                            
                            sessionStorage.setItem("basket", JSON.stringify(basket));
                            const container = document.getElementById("overlaycontainer");
                            const newitem = document.createElement("div");
                            newitem.innerHTML = content;
                            newitem.className = "overlay";
                            container.appendChild(newitem);
                        }}/>
                    )
                }) : (
                    <div>Bạn chưa bỏ vé vào giỏ hàng</div>
                )
            }
        </div>
        <div className="overlaycontainer" id="overlaycontainer">

        </div>
        <div className="SumMoney">
            <h3>Tổng số tiền: <span>{sum}</span></h3>
            <button className="Payment">Thanh toán</button>
        </div>
        </>
        
    )
}