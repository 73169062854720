import { HugeButton, IntroButton } from "../components/Buttons"
import { Link } from "react-router-dom"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import image1 from '../components/1.jpg';
import image2 from '../components/2.jpg';
import image3 from '../components/3.jpg';
import image4 from '../components/4.jpg';
import image5 from '../components/5.jpg';
import image6 from '../components/6.jpg';

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '600px'
  }
  const slideImages = [
    {
      url: image1
    },
    {
      url: image2
    },
    {
      url: image3
    },
    {
      url: image4
    },
    {
      url: image5
    },
    {
      url: image6
    }
  ];
  
  const Slideshow = () => {
      return (
        <div className="slide-container my-2">
          <Slide>
           {slideImages.map((slideImage, index)=> (
              <div key={index}>
                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                  {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                </div>
              </div>
            ))} 
          </Slide>
        </div>
      )
  }

export function Home() {
    let toggledColor = true;
    const data = [
        'Đa dạng lựa chọn về dịch vụ, tiện nghi đầy đủ'
    ]
    const intro = [3, 1, 2];
    return (
        <>
        <Slideshow/>
            <div className="banner">
                <div>
                    <h1>Trải nghiệm du lịch khác biệt</h1>
                </div>
                <div>
                    <Link to="/ticket"><HugeButton /></Link>
                </div>
            </div>
            {data.map((item, index) => {
                toggledColor = !toggledColor;
                return(
                    <div key={index} className="bannerIntro" style={toggledColor ? {backgroundColor: '#DDFFBC'} : {backgroundColor: '#FEFFDE'}}>
                        <h3>{item}</h3>
                        <Link to={'/intro/' + intro[index]}><IntroButton /></Link>
                    </div>
                )
            })}
            <div className="footer">
                <div>
                  <h5>CÔNG TY CỔ PHẦN DU LỊCH NAM ĐÀ THÀNH </h5>
                  <h6>Địa chỉ: 91 Nguyễn Lý, P. Hòa Xuân, Q. Cẩm Lệ, TP. Đà Nẵng </h6>
                  <h6>Email: cskh@dldhc.vn - Tel 0905.686.665</h6>
                  <h6>GPDKKD: 0401967732- sở KHĐT - TP Đà Nẵng 04/04/2019</h6>
                </div>
                <div>
                  <h5>CHÍNH SÁCH</h5>
                  <Link to={'/intro/2'} className="text-light"><h6>Điều khoản sử dụng</h6></Link>
                  <Link to={'/intro/0'} className="text-light"><h6>Hướng dẫn mua vé và tra cứu vé đã mua</h6></Link>
                  <Link to={'/intro/1'} className="text-light"><h6>Hướng dẫn thanh toán VNPAY</h6></Link>
                </div>
                {/* <h4>ENV: {process.env.REACT_APP_URL}</h4> */}
            </div>
        </>
    )
}