import { Home } from "./Home"
import { PickTicket } from "./PickTicket"
import { NavigateHomeandLogin, NavigateTicketPage } from "../components/Buttons"
import {Route, Routes, Link, Outlet} from "react-router-dom"
import "./style.css"
import { useState, useEffect, useContext } from "react"
import { Introduction } from "./Introduction"
import { Basket, Test } from "./Basket"
import { Profile } from "./Profile"
import { SearchPage } from "./SearchPage"
import { HiOutlineBell, HiOutlineShoppingCart, HiUserCircle, HiOutlineLogout, HiOutlineChat } from "react-icons/hi"

//import { useAuthState } from "react-firebase-hooks/auth"
import { ChatRoom } from "./Chatroom"
import { LangContext, LangDispatchContext } from "../components/Language"


export function RootBar(){
    return(
        <Routes>
            <Route path="/" element={<RootLayout />}>
                <Route index element={<Home />} />
                <Route path="ticket" element={<PickTicket />} />
                <Route path="intro/:id" element={<Introduction />} />
                <Route path="basket" element={<Test />} />
                <Route path="profile" element={<Profile />} />
                <Route path="chatroom" element={<ChatRoom />} />
                <Route path="search" element={<SearchPage />} />
            </Route>
        </Routes>
    )
}


export function RootLayout(){
    const [loading, setLoading] = useState(false);
    const lang = useContext(LangContext);
    const langDispath = useContext(LangDispatchContext);
    if(loading){
        return(<div>Đang tải...</div>);
    }
    return(
        <>
        <div className="navbar">
            <Link to="/"><NavigateHomeandLogin name={lang.home}/></Link>
            <Link to="/ticket"><NavigateHomeandLogin name={lang.book} /></Link>
            <Link to="/search"><NavigateHomeandLogin name={lang.search}/></Link>
            {/* <Link to="/basket">Test</Link> */}
            <div className="dropdown">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span className={"fi fi-" + lang.flag}></span>{" " + lang.language} 
            </button>
            <ul className="dropdown-menu">
                <li><button className="dropdown-item" onClick={() => {langDispath({type: "vn"})}}>Tiếng Việt</button></li>
                <li><button className="dropdown-item" onClick={() => {langDispath({type: "eng"})}}>English</button></li>
            </ul>
            </div>
        </div>
        <Outlet />
        </>
    )
}